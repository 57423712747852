import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Hero from "../components/hero";
import {graphql} from "gatsby";
import get from 'lodash/get'

class PrivacyPolicy extends React.Component {
    render() {
        const [author] = get(this, 'props.data.allContentfulPerson.edges')
        return (
            <Layout location={this.props.location}>
                <div style={{ background: '#fff' }}>
                    <Helmet title={`プライバシーポリシー`} />
                    <Hero data={author.node} />
                    <div className="wrapper">

                        <h1>プライバシーポリシー</h1>

                        <h2>アクセス解析ツールについて</h2>

                        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。

                        Googleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。

                        この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは<a rel="noopener" target="_blank" href="https://www.google.com/analytics/terms/jp.html">ここをクリック</a>してください。

                        <h2>広告の配信について</h2>

                        {/*当サイトは第三者配信の広告サービス「Google Adsense グーグルアドセンス」を利用しています。*/}

                        {/*広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。*/}

                        {/*Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は<a rel="noopener" target="_blank" href="https://www.google.co.jp/policies/technologies/ads/"> 広告 – ポリシーと規約 – Google</a>をご覧ください。*/}

                        当サイトは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。

                        第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザにクッキーを設定したりこれを認識したりする場合があります。

                        <h2>免責事項</h2>

                        当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。

                        当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。

                        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。

                        <h2>プライバシーポリシーの変更について</h2>

                        当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。

                        修正された最新のプライバシーポリシーは常に本ページにて開示されます。

                    </div>
                </div>
            </Layout>
        )
    }
}

export default PrivacyPolicy


export const pageQuery = graphql`
  query AuthorQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          bio {
            childMarkdownRemark {
              html
            }
          }           
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
